<template>
  <div class="welfarecenter">
    <navbarTools
      :leftArrow="true"
      :fixed="true"
      :rightText="rightText"
      :rightFn="contactFn"
    ></navbarTools>
    <div class="types">
      <van-tabs
        v-model:active="pages.takeType"
        title-active-color="#FC7301"
        title-inactive-color="#333"
        @change="typesChangeFn"
      >
        <van-tab title="待领取" name="1"></van-tab>
        <van-tab title="已领取" name="2"></van-tab>
        <van-tab title="已失效" name="3"></van-tab>
      </van-tabs>
    </div>
    <div v-if="nullList">
      <div class="welfareListBox" v-if="pages.takeType == '1'">
        <van-list
          :immediate-check="false"
          v-model:loading="loading"
          :finished="finished"
          @load="onloadFn"
        >
          <div v-for="(item, index) in equityList" :key="index">
            <div class="welfareList_item" v-if="item.equityStatusType == 'pre'">
              <div class="icon"><img :src="item.equityIcon" /></div>
              <div class="content">
                <div
                  class="fs13 c-ff9601 pb5 font-weight"
                  v-if="item.equitySceneCode == 'intojob_equity_week'"
                >
                  第{{ item.weekOrder }}周满勤最低领价值100元的工钱币
                </div>
                <div
                  class="fs13 c-ff9601 pb5 font-weight"
                  v-if="item.equitySceneCode == 'intojob_equity_month'"
                >
                  每月满勤最低领价值400元的工钱币
                </div>
                <div class="fs12 c-333 pb5">{{ item.batchName }}</div>
                <div class="fs12 c-333" v-if="item.realNameStatus == '0'">
                  完成<span class="c-fc9301 font-weight">实名认证</span>，{{
                    item.takeWaitDay
                  }}天后可领取
                </div>
                <!-- <div
                  class="fs12 c-333"
                  v-if="item.realNameStatus == '1' && item.takeWaitDay == '0'"
                >
                  <span class="c-fc9301">权益待发放,请联系管理员</span>
                </div> -->
              </div>
              <div class="operate">
                <div class="operate_sm" v-if="item.realNameStatus == '0'">
                  <div class="c-fff fs14" @click="RealNameFn">
                    实名<br />认证
                  </div>
                  <div>
                    <van-icon name="arrow" class="c-fff" />
                  </div>
                </div>
                <div
                  class="operate_sm bg-fcfcfc"
                  v-if="item.realNameStatus == '1'"
                >
                  <div class="c-333 fs14" v-if="item.takeWaitDay != '0'">
                    {{ item.takeWaitDay }}天后<br />可领
                  </div>
                  <div class="c-333 fs14" v-else>待发放</div>
                </div>
              </div>
              <img
                :src="OSS_URL + '/welfare/arrow_bottom.png'"
                class="arrow_bottom"
              />
              <img
                :src="OSS_URL + '/welfare/arrow_top.png'"
                class="arrow_top"
              />
              <div class="rules" @click="coinRulesFn(item)">使用规则</div>
            </div>
            <div
              class="welfareList_item"
              v-if="item.equityStatusType == 'wait'"
            >
              <div class="icon"><img :src="item.equityIcon" /></div>
              <div class="content">
                <div
                  v-if="item.equitySceneCode == 'first_intojob_giftbag'"
                  class="pb5"
                >
                  <span class="fs18 c-fc7201">{{ item.name }}</span>
                </div>
                <div v-else class="pb5">
                  <span class="fs23 c-fc7201 font-weight">{{
                    item.equityItemVal
                  }}</span
                  ><span class="fs11 c-fc7201">工钱币</span>
                </div>
                <div class="fs12 c-333 batch_name pb5">
                  {{ item.batchName }}
                </div>
                <div>
                  <span class="c-fc7201 fs12">{{ item.loseSurplusDay }}天</span
                  ><span class="fs12">后失效</span>
                </div>
              </div>
              <div class="operate">
                <div class="operate_sm">
                  <div
                    class="c-fff fs14"
                    v-if="item.realNameStatus == '0'"
                    @click="RealNameFn"
                  >
                    实名<br />可领
                  </div>
                  <div
                    class="c-fff fs14"
                    v-if="item.realNameStatus == '1'"
                    @click="drawFn(item)"
                  >
                    领取
                  </div>
                  <div>
                    <van-icon name="arrow" class="c-fff" />
                  </div>
                </div>
              </div>
              <img
                :src="OSS_URL + '/welfare/arrow_bottom.png'"
                class="arrow_bottom"
              />
              <img
                :src="OSS_URL + '/welfare/arrow_top.png'"
                class="arrow_top"
              />
              <div class="rules" @click="coinRulesFn(item)">使用规则</div>
            </div>
          </div>
        </van-list>
      </div>
      <div class="welfareListBox" v-if="pages.takeType == '2'">
        <van-list
          :immediate-check="false"
          v-model:loading="loading"
          :finished="finished"
          @load="onloadFn"
          finished-text="没有更多了"
        >
          <div v-for="(item, index) in equityList" :key="index">
            <div class="welfareList_item bg-ffe9cf">
              <div class="icon"><img :src="item.equityIcon" /></div>
              <div class="content">
                <div
                  class="fs18 c-fc7201 pb5"
                  v-if="item.equitySceneCode == 'first_intojob_giftbag'"
                >
                  {{ item.name }}
                </div>
                <div v-else class="pb5">
                  <span class="fs18 c-fc7201 font-weight">{{
                    item.equityItemVal
                  }}</span
                  ><span class="fs11 c-fc7201">&nbsp;工钱币</span>
                </div>
                <div class="fs12 c-33 batch_name pb5">{{ item.batchName }}</div>
                <div class="fs12 c-999">
                  领取时间: {{ item.equityTakedTime }}
                </div>
              </div>
              <div class="operate">
                <div class="operate_sm bg-ffb97f">
                  <div class="c-fff fs14">已领取</div>
                </div>
              </div>
              <img
                :src="OSS_URL + '/welfare/arrow_bottom.png'"
                class="arrow_bottom"
              />
              <img
                :src="OSS_URL + '/welfare/arrow_top.png'"
                class="arrow_top"
              />
              <div class="rules" @click="coinRulesFn(item)">使用规则</div>
            </div>
          </div>
        </van-list>
      </div>
      <div class="welfareListBox" v-if="pages.takeType == '3'">
        <van-list
          :immediate-check="false"
          v-model:loading="loading"
          :finished="finished"
          @load="onloadFn"
        >
          <div v-for="(item, index) in equityList" :key="index">
            <div
              class="welfareList_item bg-ffe9cf"
              v-if="item.equityStatusType == 'wait'"
            >
              <div class="icon"><img :src="item.equityIcon" /></div>
              <div class="content">
                <div
                  class="fs18 c-999 pb5"
                  v-if="item.equitySceneCode == 'first_intojob_giftbag'"
                >
                  {{ item.name }}
                </div>
                <div v-else class="pb5">
                  <span class="fs18 c-999 font-weight">{{
                    item.equityItemVal
                  }}</span
                  ><span class="fs11 c-999">&nbsp;工钱币</span>
                </div>
                <div class="fs12 c-999 pb5 batch_name">
                  {{ item.batchName }}
                </div>
                <div class="fs12 c-999">
                  失效时间: {{ item.equityLoseTime }}
                </div>
              </div>
              <div class="operate">
                <div class="operate_sm bg-cfcfcf">
                  <div class="c-818181 fs14">已失效</div>
                </div>
              </div>
              <img
                :src="OSS_URL + '/welfare/arrow_bottom.png'"
                class="arrow_bottom"
              />
              <img
                :src="OSS_URL + '/welfare/arrow_top.png'"
                class="arrow_top"
              />
              <div class="rules" @click="coinRulesFn(item)">使用规则</div>
            </div>

            <div
              class="welfareList_item bg-ffe9cf"
              v-if="item.equityStatusType == 'pre'"
            >
              <div class="icon"><img :src="item.equityIcon" /></div>
              <div class="content">
                <div class="pb5">
                  <span
                    class="fs12 c-999 font-weight"
                    v-if="item.equitySceneCode == 'intojob_equity_week'"
                  >
                    第{{ item.weekOrder }}周满勤最低领价值100元的工钱币</span
                  >
                  <span
                    class="fs12 c-999"
                    v-if="item.equitySceneCode == 'intojob_equity_month'"
                  >
                    每月满勤最低领价值400元的工钱币</span
                  >
                </div>
                <div class="fs12 c-999 pb5 batch_name">
                  {{ item.batchName }}
                </div>
                <div class="fs12 c-999" style="color: #ff0000">
                  工钱币发放异常,请联系客服处理
                </div>
              </div>
              <div class="operate">
                <div class="operate_sm bg-cfcfcf">
                  <div class="c-818181 fs14">已失效</div>
                </div>
              </div>
              <img
                :src="OSS_URL + '/welfare/arrow_bottom.png'"
                class="arrow_bottom"
              />
              <img
                :src="OSS_URL + '/welfare/arrow_top.png'"
                class="arrow_top"
              />
              <div class="rules" @click="coinRulesFn(item)">使用规则</div>
            </div>
          </div>
        </van-list>
      </div>
    </div>
    <div v-else class="nullList">
      <img :src="OSS_URL + '/null.png'" />
    </div>
    <van-popup
      v-model:show="show"
      class="drawShow"
      :close-on-click-overlay="false"
    >
      <div class="drawSuccess c-fff">
        <img :src="OSS_URL + '/welfare/tc.png'" class="bg" />
        <div class="content">
          <div class="title fs50">恭喜您获得</div>
          <div class="coin_num fs50">{{ showTxt.equityItemVal }}</div>
          <div class="fs18">工钱币</div>
          <div class="price fs12">价值{{ showTxt.equityItemMoney }}元</div>
          <div class="confirm" @click="confirmFn">确定</div>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import navbarTools from "@/components/navbarTools";
import { config } from "@/config";
import { defineComponent, toRefs, reactive, getCurrentInstance } from "vue";
import { ua } from "@/utils/ua";
import { useRouter, useRoute } from "vue-router";
import { equityList, recevieGiftBoxCoinEqutiy } from "@/api/equity";
import { Toast } from "vant";
export default defineComponent({
  components: {
    navbarTools,
  },
  setup() {
    console.log(`刷新福利页面`);
    const $router = useRouter();
    const $route = useRoute();
    const app = getCurrentInstance();
    const { shurenH5URL } = config;
    const state = reactive({
      rightText: "问题反馈",
      OSS_URL: process.env.VUE_APP_OSS_URL,
      show: false,
      loading: false,
      finished: false,
      active: 0,
      equityList: [],
      showTxt: {},
      pages: {
        takeType: $route.params.id,
        tenantId: localStorage.getItem("tenantId"),
        tenantUserId: localStorage.getItem("tenantUserId"),
        pageAo: {
          page: 1,
          pageSize: 10,
        },
      },
      nullList: true,
    });
    const confirmFn = () => {
      $router.go(0);
    };
    const drawFn = async (options) => {
      if (options.equitySceneCode == "first_intojob_giftbag") {
        $router.push({
          path: "/welfarecenter/entrypackage",
          query: {
            equityBatchId: options.equityBatchId,
            limitStartTime: options.limitStartTime,
            limitEndTime: options.limitEndTime,
          },
        });
      } else {
        const drawLoading = Toast.loading({
          message: "领取中,请稍等...",
          forbidClick: true,
          overlay: true,
          overlayClass: "loadingOverlay",
        });
        let rs = await recevieGiftBoxCoinEqutiy({
          equityBatchId: options.equityBatchId,
          limitStartTime: options.limitStartTime,
          limitEndTime: options.limitEndTime,
        });
        if (rs.code === 0) {
          state.showTxt = options;
          state.show = true;
        }
        drawLoading.clear();
      }
    };
    const contactFn = () => {
      if (ua.isAndroidApp || ua.isIosApp) {
        let obj = {
          router: "/srzppu/WebCommonActivity",
          parameter: { url: `${shurenH5URL}/suggestion-feedback?type=4` },
        };
        app.proxy.$bridge.handleMethods("jump", obj);
      }
    };

    const RealNameFn = () => {
      if (ua.isAndroidApp || ua.isIosApp) {
        let obj = {
          router: "/srzppu/WebCommonActivity",
          parameter: {
            url: `${shurenH5URL}/business/real-name-authentication`,
          },
        };
        app.proxy.$bridge.handleMethods("jump", obj);
      }
    };

    const coinRulesFn = (options) => {
      if (options.equitySceneCode == "first_intojob_giftbag") {
        $router.push({
          path: "/welfarecenter/packagerules",
        });
      } else {
        $router.push({
          path: "/coin/rules",
        });
      }
    };

    const equityListFn = async () => {
      let rs = await equityList(state.pages);
      if (rs.code === 0) {
        state.equityList = [...state.equityList, ...rs.data.list];
        if (state.equityList.length > 0) {
          state.nullList = true;
        } else {
          state.nullList = false;
        }
        if (state.pages.pageAo.page >= rs.data.totalpage) {
          state.finished = true;
        } else {
          state.loading = false;
        }
      }
    };
    const onloadFn = () => {
      setTimeout(() => {
        state.pages.pageAo.page = state.pages.pageAo.page + 1;
        equityListFn();
      }, 1500);
    };
    const typesChangeFn = (e) => {
      $router.replace({
        path: `/welfarecenter/${e}`,
      });
      state.pages.pageAo.page = 1;
      state.loading = false;
      state.finished = false;
      state.equityList = [];
      equityListFn();
    };
    equityListFn();
    return {
      RealNameFn,
      contactFn,
      confirmFn,
      drawFn,
      coinRulesFn,
      equityListFn,
      onloadFn,
      typesChangeFn,
      ...toRefs(state),
    };
  },
});
</script>

<style lang="scss" scoped>
.welfarecenter {
  height: 100%;
  background: #fff;
  ::v-deep(.navbar) {
    width: 375px;
    .van-nav-bar {
      .van-nav-bar__left .van-icon {
        font-size: 24px;
        color: #000;
      }
      .van-nav-bar__title {
        font-size: 18px;
        font-weight: 600;
      }
      .van-nav-bar__right .van-nav-bar__text {
        color: #333;
      }
    }
  }
  .types {
    position: relative;
    top: 51px;
    ::v-deep(.van-tabs) {
      .van-tabs__nav {
        background: #f8f8f8;
        .van-tab--active {
          .van-tab__text {
            font-weight: 600;
          }
        }
        .van-tabs__line {
          background: #fc7301;
          width: 20px;
          bottom: 20px;
        }
      }
    }
  }
  .welfareListBox {
    position: relative;
    top: 50px;
    padding-left: 10px;
    .welfareList_item {
      width: 355px;
      height: 85px;
      background: #ffe9cf;
      margin-top: 10px;
      box-shadow: 0px 2px 15px 0px rgba(0, 0, 0, 0.05);
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;
      border-radius: 8px;
      .icon {
        padding-right: 5px;
        img {
          width: 35px;
          height: 38px;
          padding-left: 5px;
        }
        .packageImg {
          width: 60px;
          height: 48px;
        }
      }
      .content {
        // line-height: 20px;
        padding-left: 5px;
        width: 560px;
        .c-ff9601 {
          color: #ff9601;
          position: relative;
        }
        .c-fc9301 {
          color: #ff9301;
        }
        .c-fc7201 {
          color: #fc7201;
        }
      }
      .operate {
        width: 150px;
        height: 85px;
        background: #fc7301;
        .operate_sm {
          width: 75px;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          .van-icon {
            position: absolute;
            top: 32px;
            right: -1px;
          }
        }
        .bg-fcfcfc {
          background: #cfcfcf;
          color: #333;
          justify-content: center;
        }
      }
      .arrow_bottom,
      .arrow_top,
      .rules {
        position: absolute;
      }
      .arrow_top {
        width: 10px;
        height: 8px;
        bottom: 0px;
        left: 275px;
      }
      .arrow_bottom {
        width: 10px;
        height: 8px;
        top: 0px;
        left: 275px;
      }
      .rules {
        position: absolute;
        left: -4px;
        height: 18px;
        background: #fc7301;
        line-height: 18px;
        text-align: center;
        color: #fff;
        top: 0px;
        font-size: 12px;
        padding: 0 5px;
        transform: scale(0.83);
      }
    }
  }
  ::v-deep(.van-popup) {
    background: transparent;
    .drawSuccess {
      height: 498px;
      width: 356px;
      .bg {
        width: 100%;
        height: 100%;
        position: relative;
        top: 99;
      }
      .content {
        position: absolute;
        z-index: 101;
        top: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        width: 100%;
        height: 100%;
        .title {
          margin-top: 100px;
        }
        .price {
          position: relative;
          top: 30px;
        }
        .confirm {
          position: absolute;
          top: 420px;
          width: 145px;
          height: 50px;
          opacity: 0;
        }
        .fs54 {
          font-size: 54px;
        }
      }
    }
  }
  .bg-ffe9cf {
    background: #ffe9cf !important;
  }
  .bg-ffb97f {
    background: #ffb97f;
    justify-content: center !important;
  }
  .bg-cfcfcf {
    background: #cfcfcf;
    justify-content: center !important;
  }
  .c-818181 {
    color: #818181;
  }
  .font-weight {
    font-weight: 600;
  }
  .batch_name {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 210px;
  }
  .nullList {
    margin-top: 200px;
    text-align: center;
    img {
      width: 138px;
      height: 163px;
    }
  }
}
</style>
